
















































@import '@design';
@import '@styleMixins';

.contact-form-desc {
  @include font-size(28px);
}

.treps-contact-form {
  .v-text-field .v-input__control .v-input__slot {
    input {
      max-height: 2.5rem;
      font-size: 1.3rem;
      letter-spacing: 0.029em;
    }
    .v-label:not(.v-label--active) {
      height: 1.4rem;
      font-size: 1.3rem;
    }
    &::before,
    &::after {
      border-color: white;
      transform: scaleX(1);
    }
  }
}
